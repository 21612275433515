// v9 compat packages are API compatible with v8 code
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';
import 'firebase/compat/storage';
import 'firebase/compat/functions'


// Initialize Firebase
const config = {
    apiKey: "AIzaSyBnq3JvDLlwJRcLLBMSfheQyih6LPQdh18",
    authDomain: "hooknote-29905.firebaseapp.com",
    databaseURL: "https://hooknote-29905.firebaseio.com",
    projectId: "hooknote-29905",
    storageBucket: "hooknote-29905.appspot.com",
    messagingSenderId: "810617771979",
    appId: "1:810617771979:web:9f17add6cd540ab5e3c84e",
    measurementId: "G-QENK9XJEH7",
  };

firebase.initializeApp(config);
export const analytics = firebase.analytics();

// const db = firebase.firestore().collection('DigitalSignage').doc('prod');
// const db = firebase.firestore().collection('DigitalSignage').doc('test');
const db = getDB()

function getDB(){
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log('DEV')
        return firebase.firestore().collection('DigitalSignage').doc('test');
    } else {
        console.log('SignBoost Prod')
        return firebase.firestore().collection('DigitalSignage').doc('prod');
    }
}


const dbScreens = db.collection('screens');
//const functions = firebase.functions();

export const authHelper = require('./FirebaseAuthHelper.js');
authHelper.init(firebase,db);

export const basicPlanFreeScreens = 3

export function logAnalyticsEvent(event){
    analytics.logEvent(event);
}

export function subscribeToScreenWithID(rawScreenID,callback,filterActive){
    //this function returns a listener, not a promise.
    //Call the listener to unsubscribe eg unsubscribe();
    //callback needs to be able to be called multiple times
    if(!!rawScreenID && rawScreenID.length >= 4){
        const screenID = rawScreenID.trim()

        let screenRef = dbScreens.doc(screenID)
        return screenRef
        .onSnapshot(screenSnap => {
            if(screenSnap.exists){
                const screenData = screenSnap.data()
                //console.log(screenData)
                if(!filterActive || screenData.active){
                    callback(screenData)
                }else{
                    callback(false)
                }
            }else{
                callback(false)
            }
        })
    }else{
        callback(false)
    }
}

// export function subscribeToParticipantsWithGameID(rawGameID,callback){
//     //this function returns a listener, not a promise.
//     //Call the listener to unsubscribe eg unsubscribe();
//     //callback needs to be able to be called multiple times
//     //callback will receive array of document snapshot data, not snapshots
//     const gameID = rawGameID.trim()
//     if(!!rawGameID && rawGameID.length >= 4){
//         return db.collection('games').doc(gameID).collection('participants').orderBy('name')
//         .onSnapshot(qSnap => {
//             if(!qSnap.empty){
//                 const participantDocs = [];
//                 qSnap.forEach((doc) => {
//                     participantDocs.push(doc.data());
//                 });
//                 callback(participantDocs)
//             }else{
//                 callback(false)
//             }
//         })
//     }else{
//         callback(false)
//     }
// }

/*
export function joinGameAsParticipant(screenname,gameData){
    const uid = authHelper.authUID()
    const hostUID = gameData.hostUID
    const gameID = gameData.gameID
    //const initializedAnswers = initiallizeAnswerArray(gameData.questions.length)
    const docRef =db.collection('games').doc(gameID).collection('participants').doc(uid)
    return docRef.get()
    .then(docSnap => {
        if(!docSnap.exists){
            return docRef.set({
                uid:uid,
                hostUID:hostUID,
                name:screenname,
                answers:[]
            })
            .then(() => {
                console.log('created new participant for this game')
                return true
            })
        }else{
            console.log('Participant exists for this game, updating screenname if neccessary')
            return docRef.update({name:screenname})
            .then(() =>{
                return true
            })
        }
    })
    .catch(error =>{
        console.error(error)
        return false
    })
}
*/

/*
export function subscribeAsParticipantWithGameID(rawGameID,callback){
    //this function returns a listener, not a promise.
    //Call the listener to unsubscribe eg unsubscribe();
    //callback needs to be able to be called multiple times
    //callback will receive array of document snapshot data, not snapshots
    console.log('test')
    const gameID = rawGameID.trim()
    if(!!rawGameID && rawGameID.length >= 4){
        const uid = authHelper.authUID()
        return db.collection('games').doc(gameID).collection('participants').doc(uid)
        .onSnapshot(docSnap => {
            if(docSnap.exists){
                const data = docSnap.data()
                console.log(data)
                callback(data)
            }else{
                return false
            }
        })
    }else{
        return false
    }
}
*/

// export function saveAnswersForGame(answer,questionIndex,gameData){
//     const uid = authHelper.authUID()
//     const gameID = gameData.gameID
//     const question = gameData.questions[questionIndex]
//     const correct = preCheckAnswer(question,answer)

//     const answerPackage = {
//         question:question,
//         questionIndex:questionIndex,
//         answer:answer,
//         correct:correct
//     }
//     console.log(answerPackage)
//     return db.collection('games').doc(gameID).collection('participants').doc(uid).update({
//         answers:firebase.firestore.FieldValue.arrayUnion(answerPackage)
//     })
//     .then(() => {
//         return true
//     })
//     .catch(error =>{
//         console.error(error)
//         return false
//     })
// }

// function preCheckAnswer(question,answer){
//     const questionType = question.questionType
//     if(questionType === 'Q_FREE'){
//         if(answer.trim() === question.answer.trim()){
//             return 'C_TRUE'
//         }else{
//             return 'C_NONE'
//         }
//     }
//     if(questionType === 'Q_MULTI' || questionType === 'Q_TF'){
//         if(answer === question.answer){
//             return 'C_TRUE'
//         }else{
//             return 'C_FALSE'
//         }
//     }
//     return 'C_NONE'
// }

export function createScreen(){
    const hostUID = authHelper.authUID()
    const screenType = "G_DEFAULT"
    const title = ''
    return generateScreenID(4)
    .then(screenID =>{
        return dbScreens.doc(screenID).set({
            dateCreated:firebase.firestore.FieldValue.serverTimestamp(),
            heartbeat:firebase.firestore.FieldValue.serverTimestamp(),
            requestHeartbeat:false,
            screenID:screenID,
            title:title,
            active:true,
            hostUID:hostUID,
            screenType:screenType,
            portraitOr:false,
            slides:[]
        })
        .then(() => {
            return screenID
        })
        .catch(error => {
            console.error(error)
            return false
        })
    })
    .catch(error => {
        console.error(error)
        return false
    })
}

export function getMaxScreens(){
    //eventually this can check against the plan. Now it is fixed
    return basicPlanFreeScreens;
}

export function requestNewScreen(){
    const maxScreens = getMaxScreens()
    return getHostScreens()
    .then(screensRes => {
        if(!!screensRes){
            //exists, now count them
            if(screensRes.length >= maxScreens){
                //Plan Max Reached
                //error
                console.log('Maximum screens reached')
                return 'maxed'
            }else{
                //continue with screen creation
                return createScreen()
            }
        }else{
            //error
            console.error('error checking host screens')
            return 'error'
        }
    })
}

// export function duplicateGame(game,callbackComplete){
//     return generateScreenID(4)
//     .then(gameID =>{
//         const newGame = {...game}
//         newGame['dateCreated'] = firebase.firestore.FieldValue.serverTimestamp()
//         newGame['gameID'] = gameID
//         newGame['archived'] = false
//         newGame['active'] = false
//         newGame['currentQuestionActive'] = false
//         newGame['currentQuestionIndex'] = -1 
//         return db.collection('games').doc(gameID).set(newGame)
//         .then(() => {
//             return db.collection('games').doc(gameID).collection('participants').doc('initializedParticipants').set({ignore:true,dateCreated:firebase.firestore.FieldValue.serverTimestamp()})
//             .then(() => {
//                 callbackComplete()
//             })
//         })
//         .catch(error => {
//             console.error(error)
//             callbackComplete()
//         })
//     })
//     .catch(error => {
//         console.error(error)
//         callbackComplete()
//     })
// }

export function checkForOwnership(screenData){
    const hostUID = authHelper.authUID()
    return (screenData.hostUID === hostUID)
}

export function updateScreen(screenID,title,screenType,slides,active,portraitOr){
    return processSlides(slides,screenID)
    .then(uploadedSlides => {
        //console.log(uploadedSlides)
        return dbScreens.doc(screenID).update({
            title:title,
            active:active,
            screenType:screenType,
            portraitOr:portraitOr,
            slides:uploadedSlides
        })
        .then(() => {
            return true
        })
    })
    .catch(error => {
        console.error(error)
        return false
    })
}

export function processSlides(slides,screenID){
    if(slides && slides.length > 0){
        const uploadPromises = []
        slides.forEach(slide =>{
            if(slide.imgURL && slide.imgURL.length > 0){
                const uploadPromise = {
                    imgURL:slide.imgURL,
                    slideTime:slide.slideTime,
                    slideType:slide.slideType,
                    markup:slide.markup || {}
                }
                uploadPromises.push(uploadPromise)
            }else{
                uploadPromises.push(uploadImageAndBuildSlide(slide,screenID))
            }
        })

        return Promise.all(uploadPromises)
        .then(respArray => {
            //console.log(respArray)
            return respArray
        })
        .catch(error =>{
            console.log('error uploading images')
            console.error(error)
            return false
        })
    }else{
        return Promise.resolve(false);
    }
}

function uploadImageAndBuildSlide(slide,screenID){
    const file = slide.file
    const salt = Math.random().toString(36).slice(-8)
    const fileName = salt +'-'+ file.name
    //const thumbnailName = 'thumb-'+salt +'-'+ file.alt
    return uploadImage(file,fileName,screenID)
    .then(resp => {
        //console.log(resp)
        const fileURL = resp
        if(!!fileURL){
            return {
                imgURL:fileURL,
                slideTime:slide.slideTime,
                slideType:slide.slideType,
                markup:slide.markup || {}
            }
        }else{
            return false
        }
    })
}

function uploadImage(file,filename,screenID){
    const hostUID = authHelper.authUID()
    //console.log('uploadingImage')
    return firebase.storage().ref().child('SignBoost/'+hostUID+'/'+screenID+'/'+filename).put(file)//.putString(file.data,'base64',{contentType:'image/jpg'})
    .then(snapshot => {
         return snapshot.ref.getDownloadURL()
        .then(downloadURL => {
            //send this back?
            return downloadURL
        })
        .catch(error => {
            console.log('error getting image URL for '+file.alt)
            console.error(error)
            return false
        })
    })
    .catch(error =>{
        console.log('error uploading image')
        console.error(error)
        return false
    })
}

export function deleteScreen(screenID,callbackComplete){
    return dbScreens.doc(screenID).delete()
    .then(() => {
        callbackComplete()
    })
    .catch(error => {
        console.error(error)
        callbackComplete()
    })
}

export function deleteImage(imageFileURL){
    return firebase.storage().refFromURL(imageFileURL).delete()
    .then(() => {
        // File deleted successfully
        console.log('file deleted')
      }).catch((error) => {
        // Uh-oh, an error occurred!
        console.error(error)
      });
}

export function setScreenActive(screenID,active){
    return dbScreens.doc(screenID).update({
        active:active
    })
    .then(() => {
        return true
    })
    .catch(error => {
        console.error(error)
        return false
    })
}

export function newContact(data){
    const formData = {...data}
    const UID = authHelper.authUID()

    formData['UID'] = UID || '' // this can be an anonymous user

    return db.collection('contactForms').add(formData)
    .then(res => {
        return res
    })
    .catch(error => {
        console.error(error)
        return false
    })
}

// export function closeGame(gameID){
//     return db.collection('games').doc(gameID).update({
//         archived:true,
//         active:false
//     })
//     .then(() => {
//         return 'SUCCESS'
//     })
//     .catch(error => {
//         console.error(error)
//         return 'ERROR'
//     })
// }

/*
export function nextQuestion(gameData){
    const gameID = gameData.gameID
    const questionsLength = gameData.questions.length
    const nextQuestionIndex = gameData.currentQuestionIndex+1

    if(nextQuestionIndex <= questionsLength){
        const update = {
            currentQuestionIndex:nextQuestionIndex,
            currentQuestionActive:true
        }
        return db.collection('games').doc(gameID).update(update)
        .then(() => {
            return 'SUCCESS'
        })
        .catch(error => {
            console.error(error)
            return 'ERROR'
        })
    }else{
        return Promise.resolve('END')
    }  
}
*/

// export function closeQuestion(gameData){
//     const gameID = gameData.gameID

//     return db.collection('games').doc(gameID).update({
//         currentQuestionActive:false
//     })
//     .then(() => {
//         return true
//     })
//     .catch(error => {
//         console.error(error)
//         return false
//     })
// }

export function getHostScreens(){
    const hostUID = authHelper.authUID()
    return dbScreens.where('hostUID', '==',hostUID).get()
    .then(qSnap => {
        if(!qSnap.empty){
            //return saved games
            const screenDocs = [];
            qSnap.forEach((doc) => {
                screenDocs.push(doc.data());
            });
            //console.log(screenDocs)
            return screenDocs
            
        }else{
            //no games yet
            return []
        }
    })
    .catch(error => {
        console.error(error)
        return null
    })
}

// export function proccessResults(participants){
//     const results = []
//     if(!!participants && participants.length > 0){
//         participants.forEach(participant => {
//             const name = participant.name
//             let points = 0
//             participant.answers.forEach(answer =>{
//                 if(answer.correct === 'C_TRUE'){
//                     points = points + answer.question.points
//                 }
//             })
//             results.push({name:name,points:points})
//         })
//     }

//     //sort Results
//     results.sort((a, b) => { if(a.points > b.points){ return -1 }else{return 1 }});
//     return results
// }

function checkScreenIDExists(rawScreenID){
    const screenID = rawScreenID.trim()
    return dbScreens.doc(screenID).get()
    .then(doc => {
        return doc.exists
    })
    .catch(error => {
        console.error(error)
    })
}

function generateScreenID(length){
    let text = "";
    const charset = "abcdefghijklmnopqrstuvwxyz";
    
    for (let i = 0; i < length; i++){
        text += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    text = text.toUpperCase();

    //check for profanity
    if(text === 'FUCK' || text === 'SHIT' || text === 'HELL' || text === 'DAMN' || text === 'BITCH' || text === 'CUNT' || text === 'DICK'){
        return generateScreenID(length)
    }

    return checkScreenIDExists(text)
    .then(exists =>{
        if(exists){
            return generateScreenID(length)
        }else{
            return text
        }
    })
}

// function  initiallizeAnswerArray(length){
//     return Array.apply(null, Array(length)).map(function () {})
// }

export function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
export function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
export function eraseCookie(name) {   
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export function ytubeIDFromUrl(url){
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length==11)? match[7] : false;
}