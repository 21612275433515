import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useParams, useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";
import Faq from "react-faq-component";
import { signUpPromo } from './Login';
const fire = require('./Firebase.js')

export default function FaqPage(props){
    const navigate = useNavigate();
    const data = {
        title: "Frequently Asked Questions",
        rows: [
            {
                title: "What is SignBoost?",
                content: <p>SignBoost is the free digital signage host that works with Amazon Fire TV, Google TV, and Chromecast.
                    <br /><br />With SignBoost, you can easily create and manage your digital signage content, and play it on any TV or display with an Chromecast, Google TV, or Amazon Fire TV. 
                    SignBoost is the easiest way to get started with digital signage, and it's free! <Link to="/admin?signup=true">Click here sign up</Link> or check out the <Link to="/features">features page</Link></p>,
            },
            {
                title: "Is SignBoost really free?",
                content:<p>Yes! The basic features are totaly free, no trials, no credit cards, no strings attached. <Link to="/admin?signup=true">Click here to get started</Link><br /><br />
                Premium plans are available, but free accounts will always have {fire.basicPlanFreeScreens} free screens and get continue to updates &amp; enhancements.</p>,
            },
            {
                title: "I'd like to request a feature.",
                content: <p>We'd love to hear your ideas! Submit your suggestions here: <Link to="/support">Suggestion Box</Link><br /><br />
                We're working hard to develop and add new features as often as possible, keep an eye out for updates!</p>,
            },
            {
                title: "How many screens can I run?",
                content: <p>Currently, you can manage a maximum of {fire.basicPlanFreeScreens} unique screens. However you can have multiple displays showing each screen. If you need to host more screens, please <Link to="/support">Contact us</Link></p>,
            },
            {
                title: "What hardware should I use?",
                content: <div>
                    <p>One of the benefits of SignBoost is that it does not require expensive proprietary hardware, and aims to be platform agnostic. Currently there are several affordable options for running displays:</p>
                    <ul>
                        <li><strong>Smart TV:</strong> We recommend using</li>
                            <ul>
                                <li><a href="https://www.amazon.ca/dp/B07ZZW18N6" target="_blank" rel="noreferrer noopener">Amazon Fire TV Stick (Lite or 4K)</a></li>
                                <li><a href="https://store.google.com/config/chromecast_google_tv" target="_blank" rel="noreferrer noopener">Chromecast with Google TV (HD or 4K)</a></li>
                                <li>Other Android TV with access to the Google Play Store or Amazon Appstore</li>
                            </ul>
                        <li><strong>Web browser:</strong> Screens can also be run on any modern browser and displayed by screen casting or directly connecting the TV to the computer or device.</li>
                        <li>Apple TV support coming soon!</li>
                        <li>If there are display devices you would like to see supported, <Link to="/support">let us know!</Link></li>
                    </ul>
                    <p><a href='https://www.amazon.ca/dp/B0BRVX28BN' target="_blank" rel="noreferrer noopener">Download on the Amazon Appstore</a></p>
                    <p><a href='https://play.google.com/store/apps/details?id=com.foxbit.crunchsign' target="_blank" rel="noreferrer noopener">Download on the Play Store</a></p>
                    </div>,
            },
            {
                title: "What file formats are accepted?",
                content: <p>JPEG, PNG, and SVG files are currently supported, with more on the way </p>,
            },
            {
                title: "How can I get support?",
                content: <p>If you have questions or need assistance, please <Link to="/support">contact us</Link> </p>,
            },
        ],
    };
    
    const styles = {
        // bgColor: 'white',
        titleTextColor: "black",
        rowTitleColor: "black",
        rowContentPaddingLeft: '10px',
        // rowContentColor: 'grey',
        // arrowColor: "red",
    };
    
    const config = {
        animate: true,
        //arrowIcon: "V",
        //tabFocus: true
    };


    return (
      <div>
        <Helmet>
            <title>{'SignBoost - FAQ | Easy, affordable digital signage'}</title>
            <meta name="description" content={'SignBoost is a free, multi-platform digital signage platform. Try SignBoost today and experience the benefits of professional-grade digital signage for yourself.'} />
        </Helmet>
        <div className='bodyDiv'>
            <br />
            <br />
            <Faq data={data} styles={styles} config={config} />

            <div className='sectionLine'></div>
            <div className="clearDiv" />
            {signUpPromo(props.user.isAnonymous)}
            <Link to="/admin?signup=true"><button className='mainButtonContrast'>{!!props.user && !props.user.isAnonymous ? 'Go to dashboard' : 'Sign up'}</button></Link>
            <div className='sectionLine'></div>
          
        </div>
      </div>
    )
}