import React, {useState, useEffect} from 'react';
import { Outlet,useSearchParams } from 'react-router-dom';
const fire = require('./Firebase.js')

let authStateListener = null
export function AuthUI(props){
    const [showSignIn, setShowSignIn] = useState(false)
    const [authChangeUser, onAuthChangeUser] = useState('unloaded');
    authStateListener = fire.authHelper.onAuthStateChange(onAuthChangeUser, authChangeUser, authStateListener)
    const mobileVisible = props.mobileVisible

    return(
        <div className="zeroPadMar">
            {showSignIn ? LoginModal(()=>setShowSignIn(false)) : null}
            {authChangeUser !== 'unloaded' ?
                    authChangeUser === 'unauth' ? <button onClick={() => setShowSignIn(true)} className={'signInButton linkButton'+(mobileVisible ? ' responsive' : '')}><i className="material-icons-outlined md-22 bottomAlignIcon">{'login'}</i> Sign in</button>
                    :authChangeUser === 'admin'? <button onClick={() => fire.authHelper.signOut()} className={'signInButton linkButton'+(mobileVisible ? ' responsive' : '')}><i className="material-icons-outlined md-22 bottomAlignIcon">{'logout'}</i> Sign out</button>
                    :authChangeUser === 'auth'? <button onClick={() => fire.authHelper.signOut()} className={'signInButton linkButton'+(mobileVisible ? ' responsive' : '')}><i className="material-icons-outlined md-22 bottomAlignIcon">{'logout'}</i> Sign out</button>
                    :null
                :<button className="signInButton linkButton" disabled={true}>Sign in</button>
                }
        </div>
    )
}

function LoginModal(dismiss){
    return(
        <div className="modal-back" >
            <div className="modal-content">
            <button onClick={dismiss} className="closeButton"> <i className="material-icons-round md-48">close</i></button>
                {<Login dismiss={dismiss} />}
            </div>
        </div>
    )
}

export function AuthRouter(props){
    let [searchParams, setSearchParams] = useSearchParams();
    const [isAnon, setAnon] = useState(true)
    useEffect(() => {
        if(props.user){
            setAnon(props.user.isAnonymous)
        }
    },[props.user])

    return(
        <div>
            {!!props.user && !isAnon ?
                <Outlet />
            :
                <Login setAnon={setAnon} isSignup={searchParams.get('signup')}/>
            }
        </div>
    )
    
}

export function Login(props){
    const dismiss = props.dismiss
    const setAnon = props.setAnon
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [conPass, setConPass] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [isSignup, setIsSignup] = useState(props.isSignup)
    return(
        <div>
            {signUpPromo(isSignup)}
            <div className="authCard">
            <h2>{isSignup ? 'Sign Up' : 'Sign In'}</h2>
            
            <label className="label">Email Address
                <input onChange={(e) => setEmail(e.target.value)} className="StripeElement stripeCustField" type="text" name="email" placeholder="name@example.com" value={email} />
            </label>
            <label className="label">Password
                <input onChange={(e) => setPass(e.target.value)} className="StripeElement stripeCustField" type="password" name="password" placeholder="Password" value={pass} />
            </label>
            {isSignup ?
                <label className="label">Confirm Password
                    <input onChange={(e) => setConPass(e.target.value)} className="StripeElement stripeCustField" type="password" name="conpassword" placeholder="Confirm Password" value={conPass} />
                </label>
            :null}
            {errorMsg && errorMsg.length > 0 ? <p className="redtext">{errorMsg}</p> : null}
            <button onClick={() => {submitClicked(email,pass,conPass,isSignup,setErrorMsg,setAnon); if(dismiss) dismiss();}} className="mainButtonContrast" disabled={false}>{isSignup ? 'Sign Up' : 'Sign In'}</button>
            <br />
            <br />
            {isSignup ? 'Already have an account?' : 'or get started for free'}<button className="linkButton" onClick={() => setIsSignup(!isSignup)}>{isSignup ? 'Sign In' : 'Sign Up'}</button> <br /><br />
            <button className="linkButton" onClick={() => fire.authHelper.forgotPassword(email, setErrorMsg)}>Forgot Password</button>
            <br />
            <br />
            </div>
        </div>
    )
}

function submitClicked(email, pass, conPass,isSignup, setErrorMsg,setAnon){
    if(isSignup){
        if(pass === conPass){
            fire.authHelper.createAccount(email,pass,conPass,setErrorMsg,setAnon)
        }else{
            setErrorMsg('The passwords you entered do not match')
        }
    }else{
        fire.authHelper.signIn(email, pass, setErrorMsg)
    }
}

export function signUpPromo(isSignUp){
    if(isSignUp){
        return (
            <div className='bodyDiv'>
                <div className='centerList'>
                <h2>Get started in under a minute</h2>
                    <ul>
                        <li>Easy setup</li>
                        <li>Remote screen management</li>
                        <li>Unlimited displays*</li>
                        <li>Flexible hardware options</li>
                        <li>No-strings free tier</li>
                    </ul>
                </div>
            </div>
        )
    }else{
        return null
    }
}
