import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Outlet, useSearchParams} from "react-router-dom";
import { loadingAnnimation } from './Loading.js';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Helmet } from 'react-helmet';

const fire = require('./Firebase.js')

export function ScreenLauncher(props){
    const [screenCode, setScreenCode] = useState('')
    let [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    return (
        <div>
            <div className='sectionLine'></div>
            <h2>Display a screen</h2>
            <label>Enter your screen code<br /> <br />
            <input onChange={(e) => setScreenCode(e.target.value.replace(/\W/g, '').trim().toUpperCase())} onKeyDown={(e) => {
                if(e.key === 'Enter' && screenCode.length >= 4){
                    navigate('/screen/'+screenCode+'?'+searchParams.toString())
                }
                }} className="gameCodeInput StripeElement stripeCustField" type="text" name="code" placeholder="e.g. QYXK" value={screenCode} />
            </label>
            <br />
            <button className='mainButtonContrast' onClick={() => navigate('/screen/'+screenCode+'?'+searchParams.toString())} disabled={screenCode.length <4}>Start</button>
            <Outlet />
        </div>
    )
}


let player = null;
let screenListener = null;
export function ScreenView(props){
    let params = useParams();
    const handle = useFullScreenHandle();
    //this gamedata stays live
    const [screenData, setScreenData] = useState({})
    const [currentSlide, setCurrentSlide] = useState('')
    const [fadeOut, setFadeOut] = useState(false)
    useEffect(() => {
        if(screenListener){
            screenListener();
        }
        screenListener = fire.subscribeToScreenWithID(params.screenID,setScreenData,true)
        
    }, [params.screenID])
    useEffect(() => {
        checkScreenType(screenData)

    }, [screenData])
    // useEffect(() => {
    //     setTimeout(() => {
    //         setFadeOut(false)
    //     }, 200);
        
    // }, [currentSlide])

    return (
      <div>
        <Helmet>
            <title>{'SignBoost - Screen Display | Easy, affordable digital signage'}</title>
            <meta name="description" content={'SignBoost is a free, multi-platform digital signage platform. Try SignBoost today and experience the benefits of professional-grade digital signage for yourself.'} />
        </Helmet>
        {!!screenData.screenID ?
            <div>
                <button className='fullScreenButton' title="Fullscreen" onClick={handle.enter}><i className="material-icons-outlined md-36">fullscreen</i></button>
                <FullScreen handle={handle}>
                    <div className='fullscreen'>
                        {currentSlide.slideType === "S_YTUBE" ? 
                            <iframe src="https://www.youtube.com/embed/dOd1hicbWhA?controls=0&showinfo=0&rel=0&autoplay=1&loop=1&mute=1&playlist=dOd1hicbWhA" frameborder="0" allowfullscreen autoplay style={{"width":"100%","height":"100%"}}></iframe>
                        : 
                            <img className={'slideImg '+(fadeOut? 'fadeOut' : 'fadeIn')} src={currentSlide.imgURL} onLoad={() => setFadeOut(false)} alt={screenData.title}/>
                        }
                    </div>
                </FullScreen>
            </div>
        :
            !!screenData ?
            <div>
                {loadingAnnimation(true)}
                <h2>Loading ...</h2>
            </div>
            :
                <h2>Shoot! This screen doesn't exist</h2>
        }
      </div>
    )

    function checkScreenType(screenData){
        if(!!screenData){
            fire.logAnalyticsEvent('web_display_started')
            if(screenData.screenType === 'G_DEFAULT'){
                //This player will restart every time the the screen doc is updated, including for heartbeat checks.
                playStop(player)
                playStart(screenData)
            }
        }
    }

    function playStart(screenData){
        if(!!screenData && screenData.slides && screenData.slides.length > 0){
            playStop(player)
            if(screenData.slides.length === 1){
                setCurrentSlide(screenData.slides[0]);
            }else{
                player = playNext(screenData.slides,0,(e) => {
                    setFadeOut(true)
                    setTimeout(() => {
                        setCurrentSlide(e);
                    }, 300);
                })
            }
            
        }
    }

    function playNext(slides,index, callback){
        const slide = slides[index]
        const slideTime = slide.slideTime
        //console.log(imgURL)
    
        let nextIndex = index + 1
        if(nextIndex >= slides.length){
            nextIndex = 0
        }
    
        //callback(imgURL)
        callback(slide)
        return setTimeout(function() { player = playNext(slides,nextIndex,callback); }, (slideTime*1000));
    }
    
    function playStop(timeout){
        clearTimeout(timeout)
    }
}

