// v9 compat packages are API compatible with v8 code
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

require("firebase/firestore");
require("firebase/storage");
require("firebase/analytics");
require("firebase/functions");
require("firebase/auth");
//AUTH*********************

let firebase = {}
let db = {}
export let auth = {}
export function init(firebase0,db0){
    firebase = firebase0
    auth = firebase.auth()
    db = db0
}

let authTokenClaims = null

export function authUID(){
    const currentUser = getCurrentUser()
    if(currentUser){
        return currentUser.uid
    }else{
        return false
    }
}

export function authChanged(callback){
    return auth.onAuthStateChanged((user) => {
        if (user) {
            callback(true)
        }else{
            callback(false)
        }
    })
}

export function getCurrentUser(){
    if(auth.currentUser){
        return auth.currentUser
    }else{
        return false
    }
}

export function signOut() {
    auth.signOut();
    anonSignIn();
}

export function signIn(email,password,callback) {
    if(email.length <= 0){
        callback('Please enter a valid email address.')
    }else{
        authPersist()
        auth.signInWithEmailAndPassword(email, password)
        .then((userCredential) => {
            //successful log in
            console.log('sign in success')
            firebase.analytics().logEvent('login');
            callback('')
            return true
        })
        .catch(error => {
            console.log(error)
            callback('The email or password you entered doesnt match our records. Please try again or click Forgot Password.')
        });
    }
}

export function checkCreateUserRecord(uid,email){
    db.collection('users').doc(uid).get()
    .then(snap => {
        if(!snap.exists){
            //create user record
            createUserRecord(uid,email)
        }else{
            //user record exists, all good
        }
    })
    .catch(error => {
        console.error(error)
    });
}

export function anonSignIn(){
    auth.signInAnonymously()
    .then(() => {
        // Signed in..
    })
    .catch((error) => {
        //var errorCode = error.code;
        var errorMessage = error.message;
        console.error(errorMessage)
    });
}

// export function setPassword(password, loadingState, callback){
//     loadingState(true)
//     const user = auth.currentUser
//     user.updatePassword(password)
//     .then(() => {
//         db.collection('members').doc(user.uid).update({
//             isNew:false
//         })
//         .then(() => {
//             loadingState(false)
//             callback('success')
//         })
//         .catch(error => {
//             loadingState(false)
//             callback('failed')
//         })
//     })
//     .catch(error => {
//         loadingState(false)
//         callback('failed')
//     })
// }

export function createAccount(email, password, confirmPassword, callback,setAnon){
    if(password !== confirmPassword){
        callback('The passwords you entered do not match!')
    }else if(email && email.length <= 0){
        callback('Please enter a valid email address.')
    }else{
        authPersist()
        //console.log('signUpAttempted')
        let credential = firebase.auth.EmailAuthProvider.credential(email, password);
        auth.currentUser.linkWithCredential(credential)
        .then((usercred) => {
            const user = usercred.user;
            console.log("Anonymous account successfully upgraded", user);
            console.log('sign up success')
            firebase.analytics().logEvent('sign_up');

            createUserRecord(user.uid,email)
            .then(() => {
                callback('')
                setAnon(user.isAnonymous)
            })
        }).catch((error) => {
            console.log(error.message + error.code)
            callback('Something went wrong creating your account... Please try again')
        });
    }
}

export function createUserRecord(uid,email){
    return db.collection('users').doc(uid).set({
        UID:uid,
        email:email,
        dateCreated:firebase.firestore.FieldValue.serverTimestamp(),
        plan:'basic',
        permissions:'user',
        billing:{}
    })
}

export function forgotPassword(email,callback){
    auth.sendPasswordResetEmail(email)
    .then(() => {
        callback('A password reset email has been sent to you at '+email)
        // Email sent.
    })
    .catch(error => {
        // An error happened.
        console.error(error)
        callback('The email adress you entered is not valid')
    });
}

export function onAuthStateChange(callback, authState, unsubscribe){
    if(authState === 'unloaded'){
        if(unsubscribe){
            unsubscribe()
        }
        return auth.onAuthStateChanged((user) => {
            if (user) {
                // User is signed in.
                // load favorites
                //getUserFavorites()
                
                //console.log('state change Signed In')
                user.getIdTokenResult()
                .then((idTokenResult) => {
                    // Confirm the user is an Admin.
                    authTokenClaims = idTokenResult.claims
                    if (!!idTokenResult.claims.isAdmin) {
                        // Show admin UI.
                        //console.log('is Admin')
                        callback('admin')
                    } else {
                        // Show regular user UI.
                        //console.log('is not Admin')
                        callback('auth')
                    }
                })
                .catch((error) => {
                    console.log(error);
                    callback('auth')
                });
              }else{
                authTokenClaims = null
                callback('unauth')
                console.log('state change Signed Out')
              }
        })
    }

}

export function isAdmin(callback){
    if(!!auth.currentUser){
        auth.currentUser.getIdTokenResult()
        .then((idTokenResult) => {
            // Confirm the user is an Admin.
            if (!!idTokenResult.claims.isAdmin) {
            // Show admin UI.
            callback(true)
            } else {
            // Show regular user UI.
            callback(false)
            }
        })
        .catch((error) => {
            console.log(error);
            callback(false)
        });
    }else{
        callback(false)
    }
    

}

export function isAgency(callback){
    if(!!auth.currentUser){
        auth.currentUser.getIdTokenResult()
        .then((idTokenResult) => {
            // Confirm the user is an Admin.
            if (!!idTokenResult.claims.agency) {
            // Show admin UI.
            callback(idTokenResult.claims.agency)
            } else {
            // Show regular user UI.
            callback(false)
            }
        })
        .catch((error) => {
            console.log(error);
            callback(false)
        });
    }else{
        callback(false)
    }
    

}


function authPersist() {
    //console.log('signInAttempted')
    auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(function() {
        //auth is persisted
    })
    .catch(error => {
        if(error){
        console.log(error.message + error.code)
        //alert(error.message)
        }
    });
}
