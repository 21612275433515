import { getBase64Strings } from 'exif-rotate-js/lib';
import imageCompression from 'browser-image-compression';

const Compress = require('compress.js').default;

const prefrences = {
    //defaults can be overriden
    maxImageDimension:3840, //3840 is 4k 
    maxFileSize: 3.0, //size in MB
    targetSize: 1.0, //files under this size will not be compressed
    jpgQuality: 0.92, //standard is around 0.9
    rotate:false //generally for dealing with exif files, if true then all non-vector files will be run through rotate. Can affect compression quality
}

export function ImgProcTest(props){
    //Component for testing only
    return (
        <input onChange={(e) => {processFiles(e.target.files)}} type="file" name="image" accept="image/*" multiple />
    )
}

function compressImagesMultiThread(rawFiles){
    if(rawFiles && rawFiles.length > 0){
        const promises = []
        rawFiles.forEach(rawFile => {
            promises.push(compressMultiThread(rawFile))
        })
        return Promise.all(promises)
        .then(files => {
            return files
        })
    }else{
        return new Promise((resolve, reject) => {
            resolve([])
          });
    }
}

function compressMultiThread(imageFile){
    console.log(imageFile); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    var options = {
        maxSizeMB: prefrences.maxFileSize,
        maxWidthOrHeight: prefrences.maxImageDimension,
        useWebWorker: true, //multithread
        //onProgress: Function
        //exifOrientation: number,      // optional, see https://stackoverflow.com/a/32490603/10395024
        //fileType: string,             // optional, fileType override e.g., 'image/jpeg', 'image/png' (default: file.type)
        //initialQuality: number,       // optional, initial quality value between 0 and 1 (default: 1)
        //alwaysKeepResolution: boolean // optional, only reduce quality, always keep width and height (default: false)
    }
    return imageCompression(imageFile, options)
    .then(function (compressedFile) {
        console.log(compressedFile); // true
        console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

        if(imageFile.size < compressedFile.size){
            return imageFile
        }else{
            return compressedFile
        }
    })
    .catch(function (error) {
        console.log(error.message);
    });

}

export function processFiles(rawFiles){
    rawFiles = [...rawFiles]
    //console.log(rawFiles)
    //check file type, rotate if not SVG or PDF
    if(prefrences.rotate){
        return filterRotate(rawFiles)
        .then(rotatedFiles =>{
            return filterCompress(rotatedFiles)
            .then()
        })
    }else{
        return filterCompress(rawFiles)
    }
}

function filterRotate(rawFiles){
    const skipRotate = []
    const toRotate = []
    rawFiles.forEach(file => {
        if(file.type === 'image/svg+xml' || file.type === 'application/pdf'){
            skipRotate.push(file)
        }else{
            toRotate.push(file)
        }
        
    })
    return rotateImages(toRotate)
    .then(rotatedFiles => {
        //Check if file is over target size, if so, compress
        return rotatedFiles.concat(skipRotate)
    })
}


function rotateImages(rawFiles){
    //const contentType = 'image/jpg';
    //console.log(rawFiles)
    return getBase64Strings(rawFiles, { maxSize: prefrences.maxImageDimension, type:'image/jpeg', quality:1.0 }) //Quality should not be compressed here, keep at 1.0
    .then(data => {
      let newRaws = []
      //console.log(data)
      data.forEach(image => {
        const i = data.indexOf(image)
        //Compress.convertBase64ToFile(image.split(',')[1], 'image/jpeg')
        const newFile = new File([Compress.convertBase64ToFile(image.split(',')[1], 'image/jpeg')], rawFiles[i].name, {type: 'image/jpeg'})
        newRaws.push(newFile) 
      })
      //console.log(newRaws)
      return newRaws
    })
    .catch(error => {
        console.error(error)
    })
}


function filterCompress(rawFiles){
    const skipCompress = []
    const toCompress = []
    rawFiles.forEach(file => {
        const fileSize = file.size / (1024 ** 2)
        if(fileSize > prefrences.targetSize){
            toCompress.push(file)
        }else{
            skipCompress.push(file)
        }
    })
    
    // return compressImageFiles(toCompress)
    // .then(compressedFiles => {
    //     return compressedFiles.concat(skipCompress)
    // })
    return compressImagesMultiThread(toCompress)
    .then(compressedFiles => {
        return compressedFiles.concat(skipCompress)
    })
}


// function compressImageFiles(rawFiles){
//     //console.log('raw', rawFiles)
  
//     const compress = new Compress()
//     let options ={
//       size: prefrences.maxFileSize, // the max size in MB, defaults to 2MB
//       quality: prefrences.jpgQuality, // the quality of the image, max is 1,
//       maxWidth: prefrences.maxImageDimension, // the max width of the output image, defaults to 1920px
//       maxHeight: prefrences.maxImageDimension, // the max height of the output image, defaults to 1920px
//       resize: false, // defaults to true, set false if you do not want to resize the image width and height
//       rotate:true,
//     }
  
//     return compress.compress([...rawFiles], options).then((data) => {
//         const newFiles = []
//         data.forEach(image => {
//             const i = data.indexOf(image)
//             const uncompressedVersion = rawFiles[i]

//             const newFile = new File([Compress.convertBase64ToFile(image.data, 'image/jpeg')], uncompressedVersion.name, {type: 'image/jpeg'})

//             console.log(uncompressedVersion.size, newFile.size)
//             if(uncompressedVersion.size < newFile.size){
//                 newFiles.push(uncompressedVersion)
//             }else{
//                 newFiles.push(newFile) 
//             }
//         })
//         //console.log(newFiles)
//         return newFiles
//     })
//   }