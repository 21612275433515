import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate} from "react-router-dom";
import { loadingAnnimation } from './Loading.js';
import {ImagesEditor} from './ImagesEditor.js'
const fire = require('./Firebase.js')



export default function ScreenBuilder(props){
    const navigate = useNavigate();
    let params = useParams();
    const isNew = props.isNew
    const [screenData, setScreenData] = useState(props.screenData || null)
    const [errorMessage, setErrorMessage] = useState('')
    const [authed, setAuthed] = useState(false)
    let authListener = null;
    let screenListener = null;

    const [title, setTitle] = useState('')
    const [slides, setSlides] = useState([])
    const [screenType, setScreenType] = useState('G_DEFAULT')
    const [active, setActive] = useState(false)
    const [portraitOr, setPortraitOr] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(authListener){
            authListener();
        }
        authListener = fire.authHelper.authChanged(setAuthed)
        
    },[])
    useEffect(() => {
        if(isNew && !screenData && authed){
            fire.requestNewScreen()
            .then(screenID => {
                if(!!screenID&& screenID.length > 0){
                    if(screenID === 'error' || screenID === 'maxed'){
                        setErrorMessage(screenID)
                    }else{
                        if(screenListener){
                            screenListener();
                        }
                        screenListener = fire.subscribeToScreenWithID(screenID,setScreenData,false)
                    }
                }
            })
        }else if(!isNew && !screenData && authed){
            const screenID = params.screenID
            if(!!screenID&& screenID.length > 0){
                if(screenListener){
                    screenListener();
                }
                screenListener = fire.subscribeToScreenWithID(screenID,(e) => {
                    if(!!e && fire.checkForOwnership(e)){
                        setScreenData(e)
                    }else{
                        setErrorMessage('error')
                    }
                },false)
            }
        }
    }, [authed])
    useEffect(() => {
        if(!!screenData){
            setTitle(screenData.title)
            setSlides(screenData.slides)
            setScreenType(screenData.screenType)
            setPortraitOr(screenData.portraitOr || false)
            setActive(screenData.active)
        }
    }, [screenData])

    return (
        <div className='bodyDiv'>
            {!!screenData ?
                <div>
                    <div className='dataBlock'>
                        <h2>Screen builder</h2> 
                        <h2>
                            <span className='dataBlockLight'>Screen ID: </span>
                            {screenData.screenID}
                        </h2>
                        <p className='dataBlockTip'> (Enter this code in on your display in the SignBoost app)</p>
                        <br />
                        <TextEdit label={'Screen Nickname'} text={title} callback={setTitle} />
                        <div className='sectionLine'></div>
                        <label className='dataBlockLight'>Slides:</label>
                    </div>
                    <ImagesEditor slides={slides} callback={setSlides} />
                    <div className='dataBlock'>
                        <div className='sectionLine'></div>
                        <button disabled={slides.length > 15 || loading} className='mainButtonContrast floatRight' onClick={() => {setLoading(true); fire.updateScreen(screenData.screenID,title,screenType,slides,active,portraitOr).then(result =>  {navigate('/admin');}) }}>Save</button>
                        <div className="clearDiv" />
                        <div className='textCenter'>{loadingAnnimation(loading)}</div>
                        {!!slides && slides.length > 15 ?
                            <p className='redtext'><i>Screens can have a maximum of 15 slides. As we expand our service, more options will become available. <Link to="/faq">More Info</Link></i></p>
                        : null}
                    </div>
                </div>
            : 
                errorMessage === 'error' ?
                    <div>
                        <h3>Something went wrong... please log in and try again.</h3>
                    </div>
                :
                    errorMessage === 'maxed' ?
                        <div>
                            <h3><i>You've reached the maximum number of screens for your current plan. As we expand our service, more options will become available. <Link to="/faq">More Info</Link></i></h3>
                        </div>
                    :
                        <div>
                            {loadingAnnimation(true)}
                            <h3>loading...</h3>
                        </div>
            }
        </div>
    )
}




function TextEdit(props){
    const label = props.label
    const text = props.text
    const setText = props.callback
    const [editing, setEditing] = useState(false)
    return(
        <div className='dataBlockInline'>
            <label className='dataBlockLight'>{label+': '}
                
                {editing || !text || text.length <=0 ?
                    <input onChange={(e) => {setEditing(true); setText(e.target.value)}} className="optionInput StripeElement stripeCustField" type="text" name="name" placeholder="" value={text || ''} />
                :
                    <h3 className='EditableH'>{text}</h3>
                }
                <button className="barButton" onClick={() => setEditing(!editing)}><i className="material-icons-outlined md-28">{editing ? 'done' : 'edit'}</i></button>
            </label>
        </div>
    )
}
