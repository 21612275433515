import React, { useState,useEffect } from 'react';
import { loadingAnnimation } from './Loading.js';
const fire = require('./Firebase.js')
const imgProcessor = require('./ImageProcess.js')

export function ImagesEditor(props) {
  const callback = props.callback

  const [files, setFiles] = useState([]);
  const [loading,setLoading] = useState(false);
  useEffect(() => {
    if(!!props.slides && (!files || files.length === 0)){
      setFiles(createFileArrayItemsFromSlides(props.slides))
    }
  }, [props.slides])
  useEffect(() => {
    if(!!callback && !!files && files.length > 0){
      callback(files)
    }
  }, [files])
  
  return (
      <div>
          {files && files.length > 0?
            <div className='dataBlock'>
              {files.map(file => lineItem(file,files,setFiles))}
              <button className='mainButtonContrast' onClick={() => setFiles([...files.reverse()])}>Reverse Images</button>
              <p className='floatRight'>{files.length+' Slides'}</p>
              <div className="clearDiv" />
            </div>
          :null}
          <br />
          <br ></br>
          <label className="label">Upload Images<br />
              {/* <input onChange={(e) => compressImageFiles(e.target.files,files,setFiles)} type="file" name="image" accept="image/*" multiple /> */}
              <input onChange={(e) => {setLoading(true); processImages(e.target.files,files,(e) => {setFiles(e); setLoading(false);});}} type="file" name="image" accept="image/*" multiple />
          </label>
          {loading ? loadingAnnimation(loading) : null}
      </div>
  )
}

function lineItem(file,fileArray,fileArrayCallback){
  const fileItem = file.file
  const urlItem = file.imgURL
  const url = urlItem || URL.createObjectURL(fileItem)//'data:image/jpg;base64, '+fileItem.data//URL.createObjectURL(fileItem)
  const index = fileArray.indexOf(file)
  return(
    <div key={index} className="lineItem">
      <img className="lineImage" src={url} alt={'Image '+(index+1)} />
      {urlItem ? 'Image '+(index+1) : fileItem.alt}
      <div className='numInputContainer'>
        <i className="material-icons-outlined md-36 featureIcon" title="Slide interval">av_timer</i>
        <input title="Slide interval" type="number" min="1" step="1" onChange={(e) => changeInterval(e.target.value,file,fileArray,fileArrayCallback)} value={file.slideTime} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}} className="StripeElement numInputField" />
      </div>
      <button className="lineButton" title="Remove" onClick={() => removeFile(fileArray,index,fileArrayCallback)}><i className="material-icons-outlined md-36 featureIcon">clear</i></button> 
      {index < fileArray.length-1 ? <button className="lineButton" title="Move down" onClick={() => moveFileToIndex(fileArray,index,index+1,fileArrayCallback)}><i className="material-icons-outlined md-36 featureIcon">keyboard_arrow_down</i></button> : null}
      {index >0 ? <button className="lineButton" title="Move up" onClick={() => moveFileToIndex(fileArray,index,index-1,fileArrayCallback)}><i className="material-icons-outlined md-36 featureIcon">keyboard_arrow_up</i></button> : null}
    </div>
  )
}

function changeInterval(newInterval,slide,slides,callback){
  const index = slides.indexOf(slide)
  slide.slideTime = Number(newInterval)
  slides[index] = slide
  const newArray = Array.from(slides)
  callback(newArray)
  //console.log(newArray)
}

//you have can pass an array to firebase and it should return the array of uploaded URLs. 
//But you need to decide when and how this is done 

function createFileArrayItemsFromSlides(slides){
  if(slides && slides.length > 0){
    let fileArrayItems = []
    slides.forEach(slide => {
      fileArrayItems.push({
        imgURL:slide.imgURL,
        slideTime:slide.slideTime,
        slideType:slide.slideType,
        markup:slide.markup,
        file:null
      })
    })
    return fileArrayItems
  }else{
    return null
  }
}

function createFileArrayItemsFromFiles(files){
  const fileArray = [...files]
  if(fileArray && fileArray.length > 0){
    let fileArrayItems = []
    fileArray.forEach((file,i) => {
      const fileData = {
        imgURL:null,
        slideTime:10,
        slideType:'S_IMG',
        markup:{},
        file:file
      }
      fileArrayItems.push(fileData)
    })
    return fileArrayItems
  }else{
    return null
  }
}

function processImages(rawFiles,files,setFiles){
  //const contentType = 'image/jpg';
  //console.log(rawFiles)
  imgProcessor.processFiles(rawFiles)
  .then(compressedFiles => {
    console.log(compressedFiles)
    setFileArray(createFileArrayItemsFromFiles(compressedFiles),files, setFiles)
  })
}

function setFileArray(filelist, currentArray,callback){
  const fileListArray = filelist

  if(currentArray && currentArray.length > 0){
    callback(currentArray.concat(fileListArray))
  }else{
    callback(fileListArray)
  }
}

function removeFile(fileArray, index, callback){
  if(fileArray && fileArray.length > index){
    const file = fileArray[index]
    if(!!file.imgURL && file.imgURL.length > 0){
      fire.deleteImage(file.imgURL)
    }
    fileArray.splice(index,1)
    const newArray = Array.from(fileArray)
    callback(newArray)
  }
}

function moveFileToIndex(fileArray, fromIndex, toIndex, callback){
  if(fileArray && fileArray.length > fromIndex && fileArray.length > toIndex){
    const file = fileArray[fromIndex]
    fileArray.splice(fromIndex,1)
    fileArray.splice(toIndex, 0, file);
    const newArray = Array.from(fileArray)
    callback(newArray)
  }
}