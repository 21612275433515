import './App.css';
import React, { useState, useEffect } from 'react';
import { Link, createBrowserRouter, RouterProvider, ScrollRestoration, Outlet,redirect, useSearchParams} from "react-router-dom";
import {Manage} from './Host.js'
import {ScreenView, ScreenLauncher}  from './Screen';
import Footer from './Footer.js';
import FaqPage from './FAQ.js'
import ScreenBuilder from './ScreenBuilder.js'
import { loadingAnnimation } from './Loading.js';
import {Features, FeaturesList} from './Features';
import { Pricing, PriceList } from './Pricing';
import Support from './Support';
import { Privacy, Terms } from './Terms';
import {Helmet} from "react-helmet";
import { AuthRouter } from './Login';
const fire = require('./Firebase.js')

let authListener = null;
function App() {
  const [user, setUser] = useState(null)
  useEffect(() => {
      if(authListener){
          authListener();
      }
      authListener = fire.authHelper.auth.onAuthStateChanged((user) => {
          setUser(user)
          if(user){
            console.log(user.uid+' ')
            if(!user.isAnonymous){
              fire.authHelper.checkCreateUserRecord(user.uid,user.email)
            }
          }else{
            fire.authHelper.anonSignIn()
          }
          fire.analytics.logEvent('Page_Authed',{ name: 'lever_puzzle'});
      }) 
  },[])

  const router = createBrowserRouter([
    {
      element: <BasePage user={user} />,
      errorElement: <Error404 user={user} />,
      children: [
        {
          path: "/",
          element: <Home user={user} />,
        },
        {
          path: "admin",
          element: <AuthRouter user={user} />,
          children: [
            {
              index: true,
              element: <Manage user={user} />,
            },
            {
              path: "new",
              element: <ScreenBuilder isNew={true} />,
              children: [],
            },
            {
              path: "edit/:screenID",
              element: <ScreenBuilder isNew={false} />,
              children: [],
            },
          ],
        },
        {
          path: "screen",
          element: <ScreenLauncher />,
          children: [
            {
              path: ":screenID",
              element: <ScreenView user={user} displayOnly={true} />,
              children: [],
            },
          ],
        },
        {
          path: "faq",
          element: <FaqPage user={user} />,
        },
        {
          path: "support",
          element: <Support user={user} />,
          children: [],
        },
        {
          path: "features",
          element: <Features user={user} />,
          children: [],
        },
        {
          path: "pricing",
          element: <Pricing user={user} />,
          children: [],
        },
        {
          path: "terms",
          element: <Terms />,
          children: [],
        },
        {
          path: "privacy",
          element: <Privacy />,
          children: [],
        },
      ],
    },
    
  ]);

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  )
}

function BasePage(props) {
  let [searchParams, setSearchParams] = useSearchParams();
  return (
    <div className="App">
      {/* <Router> */}
      <ScrollRestoration />
        {searchParams.get('platform') !== 'fire' ?
          <div className="Appheader">
            <Link className='linkh1' to="/"><img className='logo' src='/logo.svg' alt="SignBoost Digital Display"/><h1>SignBoost</h1></Link>
            {!!props.user && !props.user.isAnonymous ? 
              <div>
                <button className='signInButton' title="Sign out" onClick={() => fire.authHelper.signOut()}><i className="material-icons-outlined md-36 featureIcon">logout</i></button>
                <Link to="/admin"><button className='signInButton signInOffset' title="Dashboard" ><i className="material-icons-outlined md-36 featureIcon">dashboard</i></button></Link> 
              </div>
            :
              <Link to="/admin"><button className='signInButton' title="Sign in"><i className="material-icons-outlined md-36 featureIcon">login</i></button></Link>
            }
            <Link to="/screen"><button className='signInButton signInButtonLeft' title="Display a Screen" ><i className="material-icons-outlined md-36 featureIcon">tv</i></button></Link>
            {/* <div className="logoContainer"><a href="/"><img src="/ZippLog-Glyph.svg" alt="Workplace Contact Tracing" className="logo" /></a></div> */}
            <div className="clearDiv" />
          </div>
        :
          <div className="Appheader">
            <img className='logo' src='/logo.svg' alt="SignBoost Digital Display"/><h1>SignBoost</h1>
            <div className="clearDiv" />
          </div>
        }
        <div className="routes">
          <Outlet />
          {props.children}
        </div>
          {searchParams.get('platform') !== 'fire'  ? Footer() : null}
      {/* </Router> */}
    </div>
  );
}


function Home(props){
  return (
    <div>
      <Helmet>
          <title>{'SignBoost | Easy, affordable digital signage'}</title>
          <meta name="description" content={'SignBoost is a free, multi-platform digital signage platform. Try SignBoost today and experience the benefits of professional-grade digital signage for yourself.'} />
      </Helmet>
      <div className='bodyDiv'>
        <h2>Easy, affordable digital signage</h2>
        <div className='bodyTxt'>
          <p>SignBoost is a powerful, <strong>free</strong>, and multi-platform digital signage host that is compatible with <strong> Amazon Fire TV, Google TV, Chromecast</strong>, and any web browser.</p> 
          <p>SignBoost offers an affordable way to remotely manage your digital signage content, giving you the flexibility to update and change your content from anywhere. Try SignBoost today and experience the benefits of professional-grade digital signage for yourself.</p>
          <div className='centerList'>
            <h3>Perfect for:</h3>
            <ul>
              <li>Restaurant Menu Boards</li>
              <li>Promotional Displays</li>
              <li>Retail Signage</li>
              <li>Advertising</li>
            </ul>
          </div>
        </div>
 
        <Link to="/features"><button className='mainButtonContrast'>Learn More</button></Link>
        <br /><a href='https://play.google.com/store/apps/details?id=com.foxbit.crunchsign' target="_blank" rel="noreferrer noopener">Download on the Play Store</a>
        <br /><a href='https://www.amazon.com/dp/B0BRVX28BN' target="_blank" rel="noreferrer noopener">Download on the Amazon Appstore</a>
        <div className='sectionLine'></div>
        <h3>SignBoost is the easiest way to get started with digital signage, and it's free!</h3>
        <p>Sign in to manage your signs, or sign up to start for free</p>
        <div className="clearDiv" />
        <Link to="/admin?signup=true"><button className='mainButtonContrast'>{!!props.user && !props.user.isAnonymous ? 'Go to dashboard' : 'Sign up'}</button></Link>
        <FeaturesList />
        <div className='sectionLine'></div>
        <h2>Get Started with SignBoost</h2>
        <div className="clearDiv" />
        <Link to="/admin?signup=true"><button className='mainButtonContrast'>{!!props.user && !props.user.isAnonymous ? 'Go to dashboard' : 'Sign up'}</button></Link>
        <br /><a href='https://play.google.com/store/apps/details?id=com.foxbit.crunchsign' target="_blank" rel="noreferrer noopener">Download on the Play Store</a>
        <br /><a href='https://www.amazon.com/dp/B0BRVX28BN' target="_blank" rel="noreferrer noopener">Download on the Amazon Appstore</a>
        <div className='sectionLine'></div>
        <h2>Pricing</h2>
        <p>SignBoost keeps your digital signage costs low with a generous free plan, 
          and flexible hardware options, such as Amazon Fire TV, Google TV or Chromecast.</p>
        <PriceList />
        <ScreenLauncher /><div className='sectionLine'></div>
        <h2>Need more info?</h2>
        <p>Check out the the <Link to="/faq">FAQ</Link> or <Link to="/support">Contact Us</Link></p>
        <div className='sectionLine'></div>
      </div>
    </div>
  )
}

function Error404(props){
  return (
    <BasePage user={props.user}>
    <div>
      <Helmet>
          <title>{'SignBoost | Page Not Found | Easy, affordable digital signage'}</title>
          <meta name="description" content={'SignBoost is a free, multi-platform digital signage platform. Try SignBoost today and experience the benefits of professional-grade digital signage for yourself.'} />
      </Helmet>
      <div className='bodyDiv'>
        <div className='bodyTxt'>
          <h3>404</h3>
          <h2>Sorry, we couldnt find the page you are looking for...</h2>
          <Link to='/'><p>Click here to go to our home page</p></Link>
        </div>
 
        
        <div className='sectionLine'></div>
        <h2>Need Help?</h2>
        <p>Check out the the <Link to="/faq">FAQ</Link> or <Link to="/support">Contact Us</Link></p>
        <div className='sectionLine'></div>
      </div>
    </div>
    </BasePage>
  )
}


export default App;
