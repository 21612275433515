import React, { useState, useEffect } from 'react';
import { Link, useNavigate} from "react-router-dom";
import { loadingAnnimation } from './Loading.js';

import {Helmet} from "react-helmet";
const fire = require('./Firebase.js')

//This should be the admin consol to create and edit games, and launch/start the game as host.
//Played games should be able to be replayed by coping to a new game, leaving the archived game with the participant stats


export function Manage(props){
    const navigate = useNavigate();
    const [screens, setScreens] = useState(null)
    useEffect(() => {
        if(!screens){
            fire.getHostScreens()
            .then(screens => {
                setScreens(screens)
            })
        }
    },[props.user,screens])
    return(
        <div  className='bodyDiv'>
            <Helmet>
                <title>{'SignBoost | Dashboard'}</title>
                <meta name="description" content={'SignBoost is a free, multi-platform digital signage platform. Try SignBoost today and experience the benefits of professional-grade digital signage for yourself.'} />
            </Helmet>
            <h2>Dashboard</h2>
            {screens && screens.length > 0 ? 
                    <div>
                        <h3>Manage your screens</h3>
                        {screens.map(screen => <ScreenLineItem data={screen} refresh={() => setScreens(null)} key={screen.screenID}  />)}
                        {!!screens && screens.length >= fire.getMaxScreens() ?
                            <p><i>You've reached the maximum number of screens for your current plan. As we expand our service, more options will become available. <Link to="/faq">More Info</Link></i></p>
                        :
                            <div className="lineItemLong AddLineItem" onClick={() => navigate('new')}>
                                + New Screen
                            </div>
                        }
                        <br />
                        <br /><a href='https://play.google.com/store/apps/details?id=com.foxbit.crunchsign' target="_blank" rel="noreferrer noopener">Download on the Play Store</a>
                        <br />
                        <br /><a href='https://www.amazon.com/dp/B0BRVX28BN' target="_blank" rel="noreferrer noopener">Download on the Amazon Appstore</a>
                    </div>
                : 
                    <div>
                        <h3>Create your first screen</h3>
                        <div className="lineItemLong AddLineItem" onClick={() => navigate('new')}>
                            + New Screen
                        </div>
                    </div>
                }
                <div className='sectionLine'></div>
        </div>
    )
}


  function ScreenLineItem(props){
      const screenData = props.data
      const refresh = props.refresh
      const navigate = useNavigate();
      return(
        <div className="lineItemLong" onClick={(e)=>{e.stopPropagation();}}>
            {screenData.title+' | '+ screenData.screenID}
            {screenData.active ? <button className="lineButton" title="Start Screen" onClick={() => window.open('/screen/'+screenData.screenID,'_blank')}><i className="material-icons-outlined md-36 featureIcon">play_arrow</i></button> : null}
            {<button className="lineButton" title={screenData.active ? 'Inactivate Screen' : 'Activate Screen'} onClick={() => {fire.setScreenActive(screenData.screenID,!screenData.active).then(res =>{if(res){refresh()}})}}><i className="material-icons-outlined md-36 featureIcon">{screenData.active ? 'toggle_on' : 'toggle_off'}</i></button>}
            {<button className="lineButton" title="Edit" onClick={() => navigate('edit/'+screenData.screenID)}><i className="material-icons-outlined md-36 featureIcon">edit</i></button>}
            
            
        </div>
      )
  }