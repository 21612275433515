import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useParams, useNavigate} from "react-router-dom";

function Footer(){
    return(
        <footer>
          <img src='/logo.svg' alt="SignBoost" className="footerBrand" />
          <div className="footerInfo">
            <Link to="/faq"><h2 className="footer">FAQ</h2></Link>
            <Link to="/features"><h2 className="footer">Features</h2></Link>
            <Link to="/pricing"><h2 className="footer">Pricing</h2></Link>
            <Link to="/support"><h2 className="footer">Support</h2></Link>
          </div>
          <div className="lightLine" />
          <p className="footer textRight"><Link to="/terms">Terms</Link> | <Link to="/privacy">Privacy</Link> | © 2022 Foxbit Software Ltd. | <span className="powered">Powered by <a className="powered" href="https://foxbit.ca">Foxbit</a></span></p>
        </footer>
    )
}

export default Footer