import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useParams, useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";
import Faq from "react-faq-component";
const fire = require('./Firebase.js')

export function Features(props){
    const navigate = useNavigate();

    return (
      <div>
        <Helmet>
            <title>{'SignBoost - Features | Easy, affordable digital signage'}</title>
            <meta name="description" content={'SignBoost is a free, multi-platform digital signage platform. Try SignBoost today and experience the benefits of professional-grade digital signage for yourself.'} />
        </Helmet>
        <br />
        <br />
        
        
        
        <div className='bodyDiv'>
            <FeaturesList />
            <div className='sectionLine'></div>
            <div className="clearDiv" />
            <h2>Get started in under a minute</h2>
            <Link to="/admin?signup=true"><button className='mainButtonContrast'>{!!props.user && !props.user.isAnonymous ? 'Go to dashboard' : 'Sign up'}</button></Link>
            <div className='sectionLine'></div>
          
        </div>
      </div>
    )
}

export function FeaturesList(props){
    return (
        <div>          
          <FeatureBlock side="left" img='./promo/M1.svg' alt='Easy Setup & Management'>
              <h2>Easy Setup & Management</h2>
              <p>SignBoost is a user-friendly digital signage solution that helps you make the most 
                of your display.</p><p>With its intuitive content management and powerful controls, you 
                can quickly and easily customize your display to fit your needs. SignBoost's user-friendly 
                interface makes it easy to get started, so you can focus on creating engaging content that 
                will impress your audience.</p><p><Link to="/admin?signup=true">Try SignBoost today</Link></p>
          </FeatureBlock> 
          <FeatureBlock side="right" img='./promo/M2.svg' alt='Unlimited Displays'>
              <h2>Unlimited Displays*</h2>
              <p>SignBoost lets you connect an unlimited* number of displays to your account, giving you the 
                flexibility to reach your audience wherever they are.</p>
                <p>With SignBoost, you can create a engaging experience that showcases your 
                content on any number of screens.</p>
                {/* <p>Whether you're running a small business or managing a large corporate network, SignBoost has the power and scalability to help you succeed. .</p> */}
                <p><Link to="/admin?signup=true">Get started today</Link> and see the difference SignBoost can make</p>
          </FeatureBlock> 
          <FeatureBlock side="left" img='./promo/M3.svg' alt='Cloud Hosted'>
              <h2>Cloud Hosted</h2>
                <p>SignBoost makes it easy to update content. With 
                a few clicks you can add, remove or edit content, and make sure your messaging 
                is always fresh and relevant.</p>
                <p>SignBoost's Cloud hosted service offers scalability, you can easily add additional 
                displays and content as your business grows. This ensures your digital 
                signage system is always up to date and ready to meet your needs.</p>
          </FeatureBlock> 
          <FeatureBlock side="right" img='./promo/M4.svg' alt='Flexible Hardware Options'>
              <h2>Flexible Hardware Options</h2>
              <p>SignBoost offers compatibility with a range of affordable and easily available 
                display hardware options, such as Amazon Fire TV, Google TV, Chromecast, and web browsers. </p>
              <p>Support for Apple TV is on the way. <a href='https://play.google.com/store/apps/details?id=com.foxbit.crunchsign' target="_blank" rel="noreferrer noopener">Download on the Play Store</a> or <a href='https://www.amazon.ca/dp/B0BRVX28BN' target="_blank" rel="noreferrer noopener">Download on the Amazon Appstore</a></p>
              <p> Learn more about what SignBoost can 
                do for your business by checking out our <Link to="/faq">FAQs</Link></p>
          </FeatureBlock> 
          <FeatureBlock side="left" img='./promo/M5.svg' alt='Free and affordable'>
              <h2>Free and affordable</h2>
              <p>SignBoost offers a generous free plan to help keep your digital signage costs low, 
                plus affordable premium plans that come with powerful, advanced features.</p>
                <p>Check out our <Link to="/pricing">Pricing Page</Link> for more info</p>
          </FeatureBlock> 
        </div>
      )
}

function FeatureBlock(props){
    return (
        <div>
            <div className='sectionLine'></div>
            <div className='featureBlock'>
                {props.side === 'left' ?
                    <div>
                        <div className='featureBlockImgContainer'>
                            <img className='featureBlockImg' src={props.img} alt={props.alt} />
                        </div>
                        <div className='featureBlockChildren'>
                            {props.children}
                        </div>
                    </div>
                :
                <div>
                    <div className='featureBlockChildren'>
                        {props.children}
                    </div>
                    <div className='featureBlockImgContainer'>
                        <img className='featureBlockImg' src={props.img} alt={props.alt} />
                    </div>
                </div>
                }
            </div>
        </div>
      )
}