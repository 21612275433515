import './App.css';
import React from 'react';
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";
import { signUpPromo } from './Login';
const fire = require('./Firebase.js')

export function Pricing(props){

    return (
      <div>
        <Helmet>
            <title>{'SignBoost - Pricing | Easy, affordable digital signage'}</title>
            <meta name="description" content={'SignBoost is a free, multi-platform digital signage platform. Try SignBoost today and experience the benefits of professional-grade digital signage for yourself.'} />
        </Helmet>
        <br />
        <br />
        
        <div className='bodyDiv'>
            <h2>Pricing</h2>
            {/* <p>SignBoost keeps your digital signage costs low with a generous free plan, 
                and affordable premium plans with advanced features (coming soon).</p> */}
            <p>SignBoost keeps your digital signage costs low with a generous free plan, 
                and flexible hardware options, such as Amazon Fire TV, Google TV or Chromecast.</p>
            <div className='sectionLine'></div>
        </div>
        <PriceList />
        
        <div className='bodyDiv'>
            <div className='sectionLine'></div>
            <div className="clearDiv" />
            {signUpPromo(props.user.isAnonymous)}
            <Link to="/admin?signup=true"><button className='mainButtonContrast'>{!!props.user && !props.user.isAnonymous ? 'Go to dashboard' : 'Sign up'}</button></Link>
            <div className='sectionLine'></div>
          
        </div>
      </div>
    )
}

export function PriceList(props){
    return (
        <div className="FlexGrid priceDiv">
            <PriceItem highlight={true} plan="Basic" price="$0" rate="Free forever" cta="Get Started" linkto="/admin?signup=true" >
                <p>Totaly free, no trials, no credit cards, no strings attached</p>
                <ul className='textLeft'>
                    <li>Up to {fire.basicPlanFreeScreens} screens</li>
                    <li>Unlimited Displays*</li>
                </ul>
            </PriceItem>
            <PriceItem highlight={false} plan="Premium" price="" rate="Need more screens?" cta="Get in Touch" linkto="/support" >
                <p>Expanded plans with more screens and features are available!</p>
                <p>Questions? Check out the <Link to="/faq">FAQ</Link></p>
            </PriceItem>
            {/* <PriceItem highlight={false} plan="Business" price="$9" rate="Monthly" cta="Get Started" linkto="/admin" >
                <p>All features of the basic plan, plus:</p>
                <ul className='textLeft'>
                    <li>Up to 10 screens</li>
                    <li>Premium Screen Types</li>
                    <li>Premium Screen Types</li>
                </ul>
            </PriceItem>
            <PriceItem highlight={false} plan="Premium" price="$16" rate="Monthly" cta="Get Started" linkto="/admin" >
                <p>All features of the basic plan, plus:</p>
                <ul className='textLeft'>
                    <li>Up to 10 screens</li>
                    <li>Premium Screen Types</li>
                    <li>Premium Screen Types</li>
                </ul>
            </PriceItem> */}
        </div>
      )
}

function PriceItem(props){
    return (
        <div className={'GridItem priceItem'+ (props.highlight ? ' topBorderBlue' : ' topBorderGrey')}>
            <h1>{props.plan}</h1>
            {props.children}
            <p><span className="priceText">{props.price}</span><br />{props.rate}</p>
            <Link to={props.linkto}><button className={props.highlight ? 'priceButtonHightlight' : ''}>{props.cta}</button></Link>
        </div>
      )
}