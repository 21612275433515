import './App.css';
import React, { useState} from 'react';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
const fire = require('./Firebase.js')

export default function Support(props){
    const [emailBlur, setEmailBlur] = useState(false);
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('select');
    const [message, setMessage] = useState('');
    const [formState, setFormState] = useState('new');

    return (
      <div>
        <Helmet>
            <title>{'SignBoost - Support | Easy, affordable digital signage'}</title>
            <meta name="description" content={'SignBoost is a free, multi-platform digital signage platform. Try SignBoost today and experience the benefits of professional-grade digital signage for yourself.'} />
        </Helmet>
        <div className='bodyDiv'>
          <h2>Have a question? <Link to="/faq">Check out the FAQ</Link></h2>
          <div className='sectionLine'></div>
          {formState === 'new' ?
            <div>
              <h2><i>We'd love to hear from you</i></h2>
              <p>If you have suggestions, questions, or need assistance, send us a message</p>
              
              <label className={emailBlur && !validateEmail(email) ? 'labelRed' : 'label'}>Your Email*
                  <input onBlur={() => setEmailBlur(true)} onChange={(e) => setEmail(e.target.value)} className="StripeElement stripeCustField" type="text" name="email" placeholder="name@example.com" value={email} />
              </label>
              <label className="label">Subject
                <select className="StripeElement stripeCustField" name="subject" value={subject} onChange={(e) => setSubject(e.target.value)}>
                <option value="select">--Select a subject--</option>
                  <option value="suggestion">I have a suggestion</option>
                  <option value="help">I need help</option>
                  <option value="question">I have a question</option>
                  <option value="screenlimits">I would like to request higher screen limits</option>
                  <option value="other">Other</option>
                </select>
              </label>
              <label className="label">Message
                  <textarea onChange={(e) => setMessage(e.target.value)} className="textarea" type="text" name="message" placeholder="" value={message} />
              </label>
              <button onClick={() => send(email, subject, message, setFormState)} className="mainButtonContrast" disabled={!validate(subject,email,message)}>Send</button>
            </div>
          : formState === 'sent' ?
            <p>Thanks for reaching out! We will get back to you as soon as possible.</p>
          : formState === 'failed' ?
            <p>Something went wrong... please try again.</p>
          :null}
          <div className='sectionLine'></div>
          <div className="clearDiv" />
          <Link to="/admin"><button className='mainButtonContrast'>{!!props.user && !props.user.isAnonymous ? 'Go to dashboard' : 'Sign up'}</button></Link>
          <div className='sectionLine'></div>
          
        </div>
      </div>
    )
}

function send(email, subject, message, setFormState){
  setFormState('loading')
  const contact = {
      email:email,
      subject:subject,
      message:message
  }
  fire.newContact(contact)
  .then(result =>{
      if(result && result.id && result.id.length > 0){
          setFormState('sent')
      }else{
          setFormState('failed')
      }
  })
  .catch(error => {
      console.log(error)
      setFormState('failed')
  })
}

function validate(subject, email,message){
  return (validateSubject(subject) && validateEmail(email) && validateMessage(message))
}
function validateMessage(message){
  return (message && message.length > 5 && message.length < 1000)
}
function validateSubject(subject){
  return (subject && subject.length > 1 && subject !== 'select')
}
function validateEmail(email){
  return (email && email.length > 3 && email.includes('@') && email.includes('.'))
}
